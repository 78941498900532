import React  from "react";
import { Card } from "./SharedElements";

export default function Introcard() {

  return (
    <Card>
      <h2>What do you want to learn?</h2>
      _______________
    </Card>
  );
}
